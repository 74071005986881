const checkIfSessionRelatedIssue = (error: any) => {
  if (process.client) {
    const message = JSON.stringify(error.message).replace(/"/gi, "");
    if (message.endsWith("412") || message.endsWith("401")) {
      window.location.reload();
    }
  }
};

export const useMiddlewareCheck = () => {
  return {
    checkIfSessionRelatedIssue,
  };
};

export default useMiddlewareCheck;
